<template>
  <div v-myTitle :data-title="textTitle">
    <div id="main">
      <!--      <h1 class="title">TACK</h1>-->
      <h1 class="title" style="margin-top: 30px;">{{ this.$title }}</h1>
      <!--      <div class="giver">评分者：{{ name }}</div>-->
      <div class="under">下属员工</div>
      <div class="hr"></div>
      <div class="workers">
        <div class="workerItem" v-for="(item, index) in staffList" :key="index">
          <div class="workName">{{ item.name }}</div>
          <div style="display: flex; flex-direction: column">
            <button
                class="seeScore"
                @click="goto(item.list[0], item.name, item.id)"
            >
              第一次评分
            </button>
            <button
                class="seeScore"
                v-if="item.times == 2"
                @click="goto(item.list[1], item.name, item.id)"
            >
              第二次评分
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getStaffList} from "../request/api";

export default {
  name: "Scores",
  data() {
    return {
      textTitle: this.$title,
      staffList: [],
      name: "",
    };
  },
  created() {
    getStaffList().then((res) => {
      console.log(res);
      this.name = res.data.name;
      this.staffList = res.data.list;
    });
  },
  methods: {
    goto(item, staffName, staffId) {
      let staffInfo = {
        id: staffId,
        name: staffName,
        times: item.times
      }
      this.$store.commit('setStaffInfo', staffInfo)
      if (item.status == 0) {
        this.$dialog({message: "该员工尚未自评！"})
      } else if (item.status == 1) {
        this.$router.push({
          path: "/explain",
          query: {
            staffId: staffId,
            staffName: staffName
          }
        });
      } else {
        this.$router.push({
          path: '/cobweb',
          query: {
            times: item.times,
            staffId: staffId
          }
        })
      }
    },
  },
};
</script>

<style>
.title {
  color: #0089c4;
  margin: 0;
}

.under {
  margin-top: 20px;
  font-weight: bold;
}

.hr {
  height: 2px;
  width: 90%;
  background-color: rgb(107, 104, 104);
  margin: 30px auto;
  margin-bottom: 10px;
}

.workers {
  width: 90%;
  margin: 0 auto;
}

.workerItem {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 25px;
  margin: 10px 0;
  border-bottom: 1px solid;
}

.workName {
  text-align: center;
}

.van-toast--text {
  font-size: 12px !important;
}

.seeScore {
  border-radius: 5px;
  width: max-content;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  background-image: linear-gradient(#00B3F1, #00A3EF);
  margin: 8px auto;
}
</style>
